export const usePlatformSettings = () => {
  const { $i18n } = useNuxtApp();

  const domain = useState<string>('domain');
  const multinational = useState<boolean>('multinational');
  const multinationalCountryCode = useCookie<string | undefined>('multinationalCountryCode');
  const countryCode = useState<string>('countryCode');
  const platformName = useState<string>('platform');
  const countries = ['ch', 'de', 'at', 'it', 'us'];

  const contactMailsBikeable = {
    'ch': 'info@bikeable.ch',
    'de': 'info@bikeable.de',
    'at': 'info@bikeable.at',
    'us': 'info@bikeablecity.org',
    'world': 'info@bikeable.world'
  };

  const contactMailsWalkable = { 'ch': 'info@walkable.ch'}

  const setup = () => {
    const route = useRoute();
    const host = useRequestURL().host;
    const domainName = getDomainNameFromHost(host);
    const domainEnding = getDomainEndingFromHost(host);

    domain.value = host;

    // Choosing country if not set yet, depending on domain (and origin of IP-Adress in case of bikeable.co)
    // There are single country versions of bikeable (e.g. bikeable.ch, bikeable.de) and a multi-country version (bikeable.co)
    if (domainName === 'bikeablecity') {
      multinational.value = false;
      setPlatformName('bikeable');
      setCountryCode('us');
    } else if (domainName === 'bikeable') {
      setPlatformName('bikeable');
      if (domainEnding === 'world') {
        multinational.value = true;
        setCountryCodeForMultiNational();
      } else {
        multinational.value = false;
        setCountryCode(domainEnding);
      }
    } else if (domainName === 'walkable') {
      multinational.value = false;
      setPlatformName('walkable');
      setCountryCode('ch');
    } else {
      // When running locally, you can use the query parameter to set the platform and country
      if (route.query.platform === 'walkable') {
        multinational.value = false;
        setPlatformName('walkable');
      } else {
        multinational.value = false;
        setPlatformName('bikeable');
      }
      if (route.query.country) {
        const queryString = route.query.country as string;
        if (queryString === 'world') {
          multinational.value = true;
          setCountryCodeForMultiNational();
        } else {
          setCountryCode(queryString);
        }
      } else {
        setCountryCode('ch');
        multinational.value = false;
      }
    }

    // Only set locale if not set yet
    if (!locale.value) {
      // Set locale depending on browser language
      const acceptLanguage = useRequestHeaders(['accept-language'])['accept-language'];
      setLocale(findBrowserLocale(availableLocales(), parseAcceptLanguage(acceptLanguage ?? 'de')));
    }

    $i18n.setLocale(locale.value);

  };

  const setCountryCodeForMultiNational = () => {
    if (!multinationalCountryCode.value) {
      const theCountryCode = useRequestHeaders(['cf-ipcountry'])['cf-ipcountry'];
      if (theCountryCode in ['de', 'at', 'ch', 'it', 'us']) {
        multinationalCountryCode.value = theCountryCode;
        setCountryCode(theCountryCode);
        setLocale(locale.value);
      } else {
        setCountryCode('world');
      }
    } else {
      setCountryCode(multinationalCountryCode.value);
      setLocale(locale.value);
    }
  };


  const setPlatformName = (thePlatform: string) => {
    if (platformName.value != thePlatform) {
      platformName.value = thePlatform;
    }
  };


  const setCountryCode = (theCode: string) => {
    countryCode.value = theCode;
  };

  const region = useCookie<number>('selectionRegion', {
    default: () => (0)
  });

  const setRegion = (newRegion: number) => {
    region.value = newRegion;
  };

  const locale = useCookie<string>('locale');

  const filterType = useCookie<'fame' | 'shame' | undefined>('filterType', {
    default: () => undefined
  });

  const filterFixed = useCookie<boolean | undefined>('filterFixed', {
    default: () => undefined
  });

  const filterOfficialAnswer = useCookie<boolean | undefined>('filterOfficialAnswer', {
    default: () => undefined
  });

  const filterArchived = useCookie<string | undefined>('filterArchived', {
    default: () => undefined
  });

  const selectedCategorieIds = useCookie<number[]>('selectedCategorieIds', {
    default: () => []
  });

  const filterMinDate = useCookie<string | undefined>('filterMinDate', {
    default: () => undefined
  });

  const filterMaxDate = useCookie<string | undefined>('filterMaxDate', {
    default: () => undefined
  });


  function getFilterParams(): any {
    const params: any = {};

    if (region.value != undefined && region.value != 0) {
      params['regionId'] = region.value;
    }

    if (filterType.value == 'fame') {
      params['famed'] = true;
    } else if (filterType.value == 'shame') {
      params['famed'] = false;
    }

    if (filterFixed.value != undefined && filterType.value != 'fame') {
      params['fixed'] = filterFixed.value;
    }

    if (filterOfficialAnswer.value != undefined) {
      params['officialAnswer'] = filterOfficialAnswer.value;
    }

    if (filterArchived.value != undefined) {
      params['archived'] = filterArchived.value;
    }

    if (
      selectedCategorieIds.value.length > 0
    ) {
      params['categoryIds'] = selectedCategorieIds.value;
    }

    if (filterMinDate.value != undefined) {
      params['begin'] = filterMinDate.value;
    }

    if (filterMaxDate.value != undefined) {
      params['end'] = filterMaxDate.value;
    }

    return params;
  }

  const availableLocales = (): string[] => {
    if (countryCode.value === 'us') {
      return ['en'];
    } else if (countryCode.value === 'at') {
      return ['de'];
    } else if (countryCode.value === 'de') {
      return ['de'];
    } else if (countryCode.value === 'it') {
      return ['it'];
    } else {
      return ['de', 'fr', 'it', 'en'];
    }
  };

  const setLocale = (newLocale: string) => {
    if (countryCode.value === 'us') {
      locale.value = 'en';
    } else if (countryCode.value === 'at' || countryCode.value === 'de') {
      locale.value = 'de';
    } else if (countryCode.value === 'it') {
      locale.value = 'it';
    } else if (availableLocales().includes(newLocale)) {
      locale.value = newLocale;
    } else {
      if (countryCode.value === 'world') {
        locale.value = 'en';
      } else {
        locale.value = 'de';
      }
    }
    console.log('Setting locale to ' + locale.value);
    $i18n.setLocale(locale.value);
  };

  function getDomainNameFromHost(host: string) {
    const parts = host.split('.');
    if (parts.length === 3) {
      return parts[1];
    } else {
      return parts[0];
    }
  }

  function getDomainEndingFromHost(host: string) {
    const parts = host.split('.');
    if (parts.length === 3) {
      return parts[2];
    } else {
      return parts[1];
    }
  }


  function getContactEmail(): string {
    if (platformName.value === 'bikeable') {
      if (countryCode.value in contactMailsBikeable) {
        return contactMailsBikeable[countryCode.value];
      } else {
        return contactMailsBikeable['world'];
      }
    } else {
      return contactMailsWalkable['ch'];
    }

  }

  return {
    setup,
    platformName,
    setPlatformName,
    countryCode,
    setCountryCode,
    region,
    setRegion,
    locale,
    filterType,
    filterFixed,
    filterOfficialAnswer,
    filterArchived,
    selectedCategorieIds,
    filterMinDate,
    filterMaxDate,
    getFilterParams,
    availableLocales,
    setLocale,
    domain,
    multinational,
    multinationalCountryCode,
    countries,
    getContactEmail
  };

};
